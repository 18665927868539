import React from "react";
import style from "./Studio.module.scss";
import { Link } from "react-router-dom";

export default function StudioOptionBox({
  option,
}: {
  option: {
    title: string;
    message: string;
    available: boolean;
    icon?: any;
    to?: string;
  };
}) {
  return (
    <Link to={option?.to ? option?.to : "/"}>
      <div
        className={
          option?.available === true
            ? style.option_container
            : style.option_container_not
        }
      >
        <div className={style.option_icon}>{option.icon}</div>
        <div className={style.option_title}>{option.title}</div>
        <div className={style.option_message}>{option.message}</div>
      </div>
    </Link>
  );
}
