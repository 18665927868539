import React, { useState } from "react";
import styles from "./NftDrops.module.scss";
import { CourseUrl, DirectusImage } from "app/core/common";
import { truncate } from "app/helpers/helper";
import { Link } from "react-router-dom";
import Button from "../button/button";

export default function NftDrops({
  creator,
  handleEdit,
  handleViewLive,
  handleViewInsights,
}: {
  handleViewLive?: (arg: any) => void;
  handleEdit?: (arg: any) => void;
  handleViewInsights?: (arg: any) => void;
  creator: any;
}) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <div className={styles.creators_container}>
      <div
        className={`${styles.drops_container} ${
          !imageLoaded && styles.loading
        } ${imageError && styles.error}`}
        style={{
          backgroundImage: `url(${DirectusImage(creator?.banner?.id)})`,
        }}
      ></div>
      <img
        className={imageLoaded ? styles.nft_image : styles.nft_image_loading}
        src={DirectusImage(creator?.banner?.id)}
        alt={`${creator?.id}`}
        onLoad={handleImageLoad}
        onError={handleImageError}
      />
      <div className={styles.overlay} />
      <div className={styles.club_details}>
        <div className={styles.details}>
          <p> {creator?.project_name}</p>
          <div className={styles.content}>
            {
              <>
                {creator?.status === "draft" ? (
                  <>
                    {creator?.collection_type === "support" ||
                    creator?.collection_type === "vote" ? null : (
                      <Button size="sm" onClick={() => handleEdit(creator)}>
                        Edit
                      </Button>
                    )}

                    <hr />
                    {creator?.project_status === "upcoming" ? null : (
                      <Button
                        size="sm"
                        onClick={() => handleViewInsights(creator)}
                        purple
                      >
                        Insights
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    {creator?.collection_type === "support" ? null : (
                      <Button size="sm" onClick={() => handleEdit(creator)}>
                        Edit
                      </Button>
                    )}{" "}
                    <Button size="sm" onClick={() => handleViewLive(creator)}>
                      View Live
                    </Button>
                    <hr />
                    {creator?.collection_type === "vote" ? null : (
                      <Button
                        size="sm"
                        onClick={() => handleViewInsights(creator)}
                        purple
                      >
                        Insights
                      </Button>
                    )}
                  </>
                )}
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
