import React from "react";
import styles from "./Insights.module.scss";

export default function InsightsItem({ user }) {
  const date = new Date(user?.date_created);
  const formattedDate = `${date.getFullYear()}-${String(
    date.getMonth() + 1
  ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
  return (
    <tr>
      <td className={styles.user}>{user?.user?.display_name}</td>
      <td className={styles.nfts_bougth}>{user?.collection}</td>
      <td className={styles.nft_sale_date}>{formattedDate}</td>
      <td className={styles.nft_sale_date}>{user?.number_of_nfts}</td>
      <td className={styles.nft_sale_date}>{user?.price}</td>
    </tr>
  );
}
